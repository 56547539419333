.App {
  text-align: center;
  
}


/* Nav Bar */
.navLinks {
  font-size: large;
  font-weight: 600;
}

/* Hover */
.textHoverEffect {
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.textHoverEffect:hover {
  -webkit-transform: scale(1.04);
  -ms-transform: scale(1.04);
  transform: scale(1.04);
}

.footerIconHoverEffect {
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.footerIconHoverEffect:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.runnerCardHoverEffect-Outer {
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.runnerCardHoverEffect-Outer:hover {
  background: #ff9100;
}

.storytellerHoverEffect {
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.storytellerHoverEffect:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.endRacismCardHoverEffect {
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.endRacismCardHoverEffect:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}


/* Drop Cap in Bio */
.firstcharacter {
  color: #e65100;
  float: left;
  font-family: Georgia;
  font-size: 50px;
  line-height: 25px;
  padding-top: 10px;
  padding-right: 8px;
  padding-left: 3px;
}

/* Text Responsiveness */
.text-responsive {
  font-size: calc(100% + 1vw + 1vh);
}

/* Bootstrap 4 breakpoints */
/* Small devices (landscape phones, 544px and up) */
@media (min-width: 544px) {  
  h1 {font-size:1.5rem;} /*1rem = 16px*/
  .cardContainer {
    width: 95%;
    height: 90%;
    }
  .cardWidth {width: 95%;}
  
}
 
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
  h1 {font-size:2rem;} /*1rem = 16px*/
  .cardContainer {
    width: 95%;
    height: 90%;
    }
  .cardWidth {width: 80%;}
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  h1 {font-size:2.5rem;} /*1rem = 16px*/
  .cardContainer {
    width: 95%;
    height: 90%;
    }
  .cardWidth {width: 70%;}
}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
  h1 {font-size:3rem;} /*1rem = 16px*/    
}