.carousel-item {
    height: 30vh;
}

.carousel-item img {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    height: 30vh;
}

@media (min-width: 576px) {

    .carousel-item {
        height: 50vh;
    }

    .carousel-item img {
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
        height: 50vh;
    }
}

@media (min-width: 768px) {

    .carousel-item {
        height: 70vh;
    }

    .carousel-item img {
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
        height: 70vh;
    }

}

@media (min-width: 992px) {}